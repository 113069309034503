<template>
	<div class="container" id="container">
		<div style="position: relative;display: flex;">
			<div class="explain_Answer1 col-md-1">
				<div class="aboust_bott20" style="margin-bottom: 20px;">
					<p class="explain_right buttonBase">
						<span @click="startRecording" v-if="!videoStart">{{$store.state.teachlanguagedata.StartRecording}}</span>
						<span @click="stopRecording" v-if="videoStart" id="btn-stop-recording">{{$store.state.teachlanguagedata.EndRecording}}</span>
					</p>
					<p class="explain_right buttonBase" v-if="data.questionOutVo" @click="gettiqing()">{{$store.state.teachlanguagedata.answers}} </p>
					<div class="explain_right buttonBase" @click="canves_show=true" :class="[canves_show?'selectmintopicindexs':'']">
						{{$store.state.teachlanguagedata.comments}}
					</div>
				</div>
			</div>
			<div class="container_con col-md-10 height85vh">
				<div class="container_con_html" @mousewheel.prevent="rollImg" @touchstart="touchStart" @touchmove="touchmove">
					<div style="display: flex;justify-content: space-between;">
						<div v-html="content" style="text-align: left;" id="container_con_htmltigan"
							 class="container_con_htmltigan" ref="imgDiv">
						</div>
					</div>
				</div>
			</div>
			<div class="explain_Answer2  col-md-1">
				<div class="aboust_bott20" style="margin-bottom: 20px;">
					<p class="explain_right buttonBase">
						<span @click="startRecording" v-if="!videoStart">{{$store.state.teachlanguagedata.StartRecording}}</span>
						<span @click="stopRecording" v-if="videoStart" id="btn-stop-recording">{{$store.state.teachlanguagedata.EndRecording}}</span>
					</p>
					<p class="explain_right buttonBase" v-if="data.questionOutVo" @click="gettiqing()">{{$store.state.teachlanguagedata.answers}} </p>
					<div class="explain_right buttonBase" @click="canves_show=true" :class="[canves_show?'selectmintopicindexs':'']">
						{{$store.state.teachlanguagedata.comments}}
					</div>
				</div>
			</div>
		</div>
		<Board v-if="canves_show" v-on:close="close"></Board>
		<screen-recording ref="screen-recording" @streamStop="streamStop" :fileName="fileName" :paramsPlanId="$route.params.plan_id"></screen-recording>
		<div id="题情" class="explain_answer_con" :style="{'display':answer!=0 ? '':'none'}" @click="answer = 0">
			<div @click.stop="answer=1" style="zoom: 1.5;" @touchstart="touchStart" @touchmove="touchmove">
				<div class="explain_answer_con1" v-if="false">
					<div style="margin-top: 20px;" v-if="data.childrenList">
						<div class="explain_answer_con1_Base">
							{{$store.state.teachlanguagedata.SwitchTopic}}
						</div>
						<div style="display: flex;margin-top: 10px;">
							<div v-for="(item,index) in data.childrenList" 	:class="[resultindex==index?'studybutclass':'']" class="basebtn" @click="resultindex=index;gettiqing()">
								{{item.questionOutVo.index?item.questionOutVo.index:index+1}}
							</div>
						</div>
					</div>
					<div>
						<div class="explain_answer_con1_Base"> {{$store.state.teachlanguagedata.situation}} </div>
					</div>
					<div class="topicSituationBase">
						<p> {{$store.state.teachlanguagedata.submittedanswer}}:{{result.total_student_number}}</p>
						<p>{{$store.state.teachlanguagedata.actualanswer}}:{{result.submit_student_number}}</p>
						<p>{{$store.state.teachlanguagedata.answerpercentage}}:{{result.submit_rate}}</p>
						<p>{{$store.state.teachlanguagedata.correct}}:{{result.right_rate}}</p>
					</div>
					<div v-if="result.right_students&&result.right_students.length>0">
						<div class="explain_answer_con1_Base">{{$store.state.teachlanguagedata.correctstudent}}</div>
						<a v-for="(item,index) in result.right_students" :key="index"
							v-on:click="handleclick1(item)">{{item.name}} </a>
					</div>
					<div v-if="result.wrong_students&&result.wrong_students.length>0">
						<div class="explain_answer_con1_Base">{{$store.state.teachlanguagedata.incorrectstudent}}</div>
						<a v-for="(item,index) in result.wrong_students" :key="index"
							v-on:click="handleclick1(item)">{{item.name}} </a>
					</div>
					<div v-if="result.uncorrect_students&&result.uncorrect_students.length>0">
						<div class="explain_answer_con1_Base">{{$store.state.teachlanguagedata.Uncorrectedstudents}}</div>
						<a v-for="(item,index) in result.uncorrect_students" :key="index"
							v-on:click="handleclick1(item)">{{item.name}} </a>
					</div>
					<div v-if="result.unjoin_users&&result.unjoin_users.length>0">
						<div class="explain_answer_con1_Base">{{$store.state.teachlanguagedata.unansweredstudents}}</div>
						<a v-for="(item,index) in result.unjoin_users" :key="index+1"
							@click="handleclick1(item)">{{item.name}} </a>
					</div>
				</div>
				<div class="explain_answer_con2" style="width:100%" @mousewheel.prevent="rollImg1" ref="imgDiv1">
					<div class="explain_answer_con1_Base"> {{$store.state.teachlanguagedata.answers}} </div>
					<div style="display:flex;flex-wrap: wrap;">
					<span id="questionanswerList" class="topicSituationBase" style="margin-right:20px;display:flex;"
						v-for="(item,index) in result.questionOutVo.answerList" :key="index">{{index+1}}.<span
							v-html="item.value"></span></span></div>
					<div class="explain_answer_con1_Base"> {{$store.state.teachlanguagedata.analysis}} </div>
					<span id="questionanalysis" class="topicSituationBase"
						v-html="result.questionOutVo.analysis"></span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import screenRecording from '../../components/screen-recording';
	import Board from '../../components/CanvasBoard/index.vue'
	export default {
		name: 'Person',
		components: {
			screenRecording,Board
		},
		computed: {},
		watch: {
			videoStart(val){
				this.$store.state.videoStart=val
			},
			originScale(val){
				console.log(val,"valjfjfjfj")
				if(this.answer==1){
					this.$refs.imgDiv1.style.zoom = parseInt(val*100) + "%";
				}else{
					this.$refs.imgDiv.style.zoom = parseInt(val*100) + "%";
				}
			},
		},
		data() {
			return {
				originScale:1,
				answer:0,
				canves_show:false,
				data:{questionOutVo:{answerList:[]}},
				result:{questionOutVo:{answerList:[]}},
				resultindex:'0',
				content:'',
				fileName:Date.parse(new Date())+'录屏',
				videoStart: false,
			}
		},
		created() {

		},
		mounted() {
			this.data=JSON.parse(localStorage.getItem('teachlupin')).name
			if(JSON.parse(localStorage.getItem('teachlupin')).stutas=='question'){
				this.content=this.data.questionOutVo.content
				if(this.data.childrenList){
					this.data.childrenList.forEach((items,indexs)=>{
						this.content=this.content+items.questionOutVo.content
						items.questionOutVo.analysis = this.textcircled(items.questionOutVo.analysis)
						if(items.questionOutVo.analysis){
							items.questionOutVo.analysis=items.questionOutVo.analysis.replace(/&amp;nbsp;/g, '')
						}
						items.questionOutVo.answerList.forEach((answerListitem, answerListindex) => {
							if (answerListitem.value.indexOf('@@@@') != -1) {
								answerListitem.value = answerListitem.value.replace(/@@@@/g, '')
								answerListitem.value = answerListitem.value.replace(/\$/g, '')
							}
							if(answerListitem.value){
								answerListitem.value = answerListitem.value.replace(/&amp;nbsp;/g, '')
							}
							answerListitem.value = this.textcircled(answerListitem.value)
						})
						this.Mathqunati();
					})
				}else{
					this.content=this.data.questionOutVo.content
					this.data.questionOutVo.analysis = this.textcircled(this.data.questionOutVo.analysis)
					if(this.data.questionOutVo.analysis){
						this.data.questionOutVo.analysis=this.data.questionOutVo.analysis.replace(/&amp;nbsp;/g, '')
					}
					this.data.questionOutVo.answerList.forEach((answerListitem, answerListindex) => {
						if (answerListitem.value.indexOf('@@@@') != -1) {
							answerListitem.value = answerListitem.value.replace(/@@@@/g, '')
							answerListitem.value = answerListitem.value.replace(/\$/g, '')
						}
						if(answerListitem.value){
							answerListitem.value = answerListitem.value.replace(/&amp;nbsp;/g, '')
						}
						answerListitem.value = this.textcircled(answerListitem.value)
					})
					this.Mathqunati();
				}
			}else{
				this.content=this.data.name
			}
			this.$forceUpdate()
		},
		beforeDestroy() {
			if(this.videoStart){
				this.stopRecording()
			}
		},
		methods: {
			// 双指放大
			touchStart(evt){
				if(evt.touches.length==1){
					this.touchStartData=null
				}else if(evt.touches.length==2){
					this.touchStartData=evt.touches
				}
			},
			touchmove(evt){
				let originScale;
				if(evt.touches.length==1){
					this.touchmoveData=null
				}else if(evt.touches.length==2){
					this.touchmoveData=evt.touches
					if(this.touchmoveData&&this.touchStartData){
						if(this.originScale<0.1){
							originScale=0.1
						}else{
							originScale=this.originScale*this.getDistance(this.touchmoveData[0],this.touchmoveData[1])/this.getDistance(this.touchStartData[0],this.touchStartData[1])
						}
						if(this.iTime){
							clearTimeout(this.iTime);
						}
						this.iTime=setTimeout(()=>{
							this.originScale=originScale
						},50);
					}
				}
			},
			getDistance(p1, p2) {
			    var x = p2.pageX - p1.pageX,y = p2.pageY - p1.pageY;
			    return Math.sqrt((x * x) + (y * y));
			},
			rollImg() {
				var zoom = parseInt(this.$refs.imgDiv.style.zoom) || 100;
				zoom += event.wheelDelta / 12;
				if(zoom > 0 )
				this.$refs.imgDiv.style.zoom = zoom + "%";
				return false;
			},
			rollImg1() {
				var zoom = parseInt(this.$refs.imgDiv1.style.zoom) || 100;
				zoom += event.wheelDelta / 12;
				this.$refs.imgDiv1.style.zoom = zoom + "%";
				return false;
			},
			textcircled(val) {
				if (val) {
					let a = ['①', '②', '③', '④', '⑤', '⑥', '⑦', '⑧', '⑨', '⑩', '⑪', '⑫']
					let data = val.replace(/\\textcircled {(1)}/g, a[0])
					data = data.replace(/\\textcircled {(2)}/g, a[1])
					data = data.replace(/\\textcircled {(3)}/g, a[2])
					data = data.replace(/\\textcircled {(4)}/g, a[3])
					data = data.replace(/\\textcircled {(5)}/g, a[4])
					data = data.replace(/\\textcircled {(6)}/g, a[5])
					data = data.replace(/\\textcircled {(7)}/g, a[6])
					data = data.replace(/\\textcircled {(8)}/g, a[7])
					data = data.replace(/\\textcircled {(9)}/g, a[8])
					data = data.replace(/\\textcircled {(10)}/g, a[9])
					data = data.replace(/\\textcelsius/g, "度")
					data = data.replace(/&amp;nbsp;/g, ' ')
					return data
				}
			},
			Mathqunati() {
				this.$nextTick(function() { //这里要注意，使用$nextTick等组件数据渲染完之后再调用MathJax渲染方法，要不然会获取不到数据
					if (this.commonsVariable.isMathjaxConfig) { //判断是否初始配置，若无则配置。
						this.commonsVariable.initMathjaxConfig();
					}
					this.commonsVariable.MathQueue("container");
					this.Mathqunatishow = true
				})
			},
			gettiqing() {
				this.answer=1
				if(this.data.childrenList){
					this.result=this.data.childrenList[this.resultindex]
					this.Mathqunati();
				}else{
					this.result=this.data
				}
			},
			explain_return() {
				this.$router.back(-1)
			},
			close(val) {
				this.canves_show = val
			},
			streamStop(){
				if(this.start){
					this.$refs['screen-recording'].stopRecording((start)=>{
						this.start= start;
					});
				}
			},
			startRecording() {
				// this.captureScreen(screen => {
				// 	this.recorder = RecordRTC(screen, {
				// 		type: 'video'
				// 	});
				// 	this.recorder.startRecording();
				// 	this.recorder.screen = screen;
				// 	this.videoStart = true;
				// });
				//开始录制
					this.videoStart = true;
				this.$refs['screen-recording'].startRecording((start)=>{
					this.start= start;
				});
			},
			stopRecording() {
				this.videoStart = false;
				// this.recorder.stopRecording(this.stopRecordingCallback);
				this.$refs['screen-recording'].stopRecording((start)=>{
					this.start= start;
				});
			},
		}
	}
</script>

<style scoped="scoped">
.selectmintopicindexs{
		background-color: #ecf5ff;
		 color: #409eff;
	}
	.topicSituationBase {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #071737;
	}

	.topicSituationBase>p {
		margin: 4px 0 0;
	}

	.font_size {
		font-size: 40px;
	}
	.font_size1 {
		font-size: 20px;
	}

	.container_con_htmltigan {
		font-size: 46px;
	}

	.explain_answer_con {
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		background: rgba(9, 30, 66, 0.2);

	}

	.explain_answer_con>div {
		width: 80%;
		height: 80%;
		background-color: #FFFFFF;
		position: absolute;
		left: 10%;
		top: 10%;
		display: flex;

	}

	.explain_answer_con1 {
		width: 30%;
		text-align: left;
		padding-left: 20px;
	}

	.explain_answer_con1_Base {
		font-size: 18px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #071737;
		line-height: 28px;
		margin-top: 24px;
	}

	.explain_answer_con2 {
		width: 80%;
		text-align: left;
		padding-left: 20px;
		overflow: auto;
	}

	.container_con {
		position: relative;
		background-color: #fff;
	}

	.container_con_html {
		padding: 20px;
		height: 78vh;
		overflow: auto;
		border-radius: 10px;
	}

	.explain_Answer1 {
		padding: 0 0.75%;
		background-color: #fff;
		border-right: 1px solid #D8DEEA;
		display: flex;
		justify-content: center;
		align-items: flex-end;
	}

	.explain_Answer2 {
		padding: 0 0.75%;
		background-color: #fff;
		border-left: 1px solid #D8DEEA;
		display: flex;
		justify-content: center;
		align-items: flex-end;
	}

	.explain_ques {
		padding: 0 20px;
		overflow: auto;
		display: flex;
		flex-wrap: nowrap;
		background-color: #FFFFFF;
	}

	.explain_ques>div {
		margin: auto;
		padding-left: 10px;
	}


	.col-center-block {
		float: none;
		display: block;
		margin: 0 auto;
	}

	.aboust_bott20 {
		white-space: nowrap;
	}

	.aboust_top20 {
		white-space: nowrap;
	}

	.container_con1 {
		font-size: 100px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>
