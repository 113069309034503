import {
	Message
} from 'element-ui'
export function WebSocketFunc(ws, params, wsUrl, callback, callback1, callback2) {
	var lockReconnect = false // 避免重复连接
	var beginTime = ''
	var sentshow = ''
	var i = 0
	var teachansDotShow = JSON.parse(localStorage.getItem("teachansDotShow"))

	function createWebSocket(url) {
		ws = new WebSocket(url)
		callback1 && callback1(ws)
		if (ws.readyState == 0) {
			setTimeout(() => {
				if (ws.readyState == 1) {
					lockReconnect = true
					ws.send(params);
					heartCheck.start()
					setInterval_web();
					ws.onmessage = function(e) {
						heartCheck.reset().start()
						sentshow = JSON.parse(e.data)
						callback && callback(e.data)
						beginTime = sentshow.current_time ? sentshow.current_time : beginTime
					};
				} else {
					lockReconnect = false
					ws.close();
					reconnect(url);
				}
			}, 1000)
		} else {
			lockReconnect = false
			ws.close();
			reconnect(url);
		}
	}

	function setInterval_web() {
		let timer = setInterval(() => {
			if (ws.readyState == 1) {} else {
				ws.close();
				reconnect(wsUrl, timer)
			}
		}, 1000)
		callback2 && callback2(timer)
	}
	//心跳检测

var heartCheck = {
    timeout: 55000,        //1分钟发一次心跳
    timeoutObj: null,
    serverTimeoutObj: null,
    reset: function(){
        clearTimeout(this.timeoutObj);
        clearTimeout(this.serverTimeoutObj);
        return this;
    },
    start: function(){
        var self = this;
        this.timeoutObj = setTimeout(function(){
            //这里发送一个心跳，后端收到后，返回一个心跳消息，
            //onmessage拿到返回的心跳就说明连接正常
            ws.send("KS000000");
            self.serverTimeoutObj = setTimeout(function(){//如果超过一定时间还没重置，说明后端主动断开了
                ws.close();     //如果onclose会执行reconnect，我们执行ws.close()就行了.如果直接执行reconnect 会触发onclose导致重连两次
            }, self.timeout)
        }, this.timeout)
    }
}
	function reconnect(wsUrl, timer) {
		i = ++i
		if (teachansDotShow || window.location.hash == '#/explain'|| window.location.hash == '#/Explain'|| window.location.hash == '#/explain1'|| window.location.hash == '#/Explain1'|| window.location.hash == '#/explain2'|| window.location.hash == '#/Explain2'|| window.location.hash == '#/explain3'|| window.location.hash == '#/Explain3') {
			if (i > 20) {
				Message({
					message: "实时数据已断开，请刷新后重新链接",
					type: 'warning'
				});
				clearInterval(timer);
				return
			}
		} else {
			if (i > 2) {
				Message({
					message: "实时数据已断开，请刷新后重新链接",
					type: 'warning'
				});
				clearInterval(timer);
				return
			}
		}
		ws.close();
		ws = new WebSocket(wsUrl)
		heartCheck.start()
		if (ws.readyState == 0) {
			setTimeout(() => {
				if (ws.readyState == 1) {
					lockReconnect = true
					ws.send(params);
					ws.onmessage = function(e) {
						heartCheck.reset().start()
						callback && callback(e.data)
					};
				} else {
					reconnect(wsUrl)
				}
			}, 5000)
		}
	}
	createWebSocket(wsUrl)
}
